import React, { useState } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import FormItem from "./FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const ContactInformationSection = () => {
  const initialValues = {
    contactName: "",
    email: "",
    phone: "",
    address: "",
  };

  const [inputValues, setInputValues] = useState(initialValues);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { open } = snackbarState;

  const handleInputChange = (e) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSaveChanges = () => {
    console.log("Saved Contact Information:", inputValues);
    openSuccessSnackbar();
  };

  const openSuccessSnackbar = () => {
    setSnackbarState({ open: true });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>
      <div className="space-y-8">
        <FormItem label="Contact Name">
          <Input
            placeholder="Contact Name"
            name="contactName"
            value={inputValues.contactName}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="E-Mail Address">
          <Input
            placeholder="E-Mail Address"
            name="email"
            value={inputValues.email}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="Phone Number">
          <Input
            placeholder="Phone Number"
            name="phone"
            value={inputValues.phone}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="Company Address">
          <Textarea
            placeholder="Company Address"
            name="address"
            rows={4}
            value={inputValues.address}
            onChange={handleInputChange}
          />
        </FormItem>

        <Grid container direction={"row"} className={"fullWidth"}>
          <div className={"flex-1"}></div>
          <ButtonPrimary onClick={handleSaveChanges}>Save Changes</ButtonPrimary>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message="Contact Information Saved"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Contact Information Saved Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactInformationSection;
