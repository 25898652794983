import React, { FC, useEffect, useState } from "react";
import BrandCard from "components/BrandCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { connect, useDispatch } from "react-redux";
import { updateLoadingIsStatus } from "../../redux/reducers/spinnerReducer";
import api from '../../api';
import { useLocation } from "react-router-dom";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  keywords: string;
  regions:string;
  user?,
  spinner?
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  keywords = '',
  regions,
  user,
  spinner
}) => {
  const [isSearchLoaded, setIsSearchLoaded] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [resultList, setResults] = useState([]);

  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get('q') ?? null;
  const c = queryParams.get('c') ?? null;

  useEffect(() => {
    if(!isSearchLoaded && user?.id != 0) {
      dispatch(updateLoadingIsStatus(true));
      let params = '';
      if(q) params += `q=${q}`;
      if(c) params += `&c=${c}`;
      params += `&company_type=${user?.company_type}`;
      params += `&region_ids=${regions}`
      
      api.search({
        params
      }, (res) => {
        setResults(res.data);
        dispatch(updateLoadingIsStatus(false));
      });

      setIsSearchLoaded(true);
    }
  }, [user]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading={`Search results ${keywords.length ? ' for "'+keywords+'"' : ''}`} />
{/* 
      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        { 
            resultList.map((brand) => {
              let brandData = {
                galleryImgs: brand.images.map((b)=>`${api.getUploadUrl()}/${b.url}`),
                title: brand.name,
                href: `/${brand.company_type=='agencies' ? 'agency' : 'brand'}/${brand.id}`,
                like: false,
                saleOff: false,
                isAds: false,
                id: `b-${brand.id}`,
                extras: brand,
              };
              console.log(brandData);
              return (
                <BrandCard key={brand.id} data={brandData} />
              )
            })
        }
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  spinner: state.spinner.isLoading,
});

export default connect(mapStateToProps, { updateLoadingIsStatus })(
  SectionGridFilterCard
);
