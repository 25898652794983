import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import MyRouter from "routers/index";
import { updateUser } from "./redux/reducers/userReducer";
import api from './api';
import { update } from "lodash";

const App = ({user}) => {
  const dispatch = useDispatch();
  const [isUserLoaded, setUserLoaded] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if(isUserLoaded) return;
    if(token == undefined || token == null) return;
    if(user.company != null) return;
    api.getUser((res) => {
      api.getUserCompanies({id: res.data.id}, (res2) => {
        let resUser = res.data;
        dispatch(updateUser({
          ...resUser,
          company: res2.data[0],
          company_type: res2.data[0].type,
        }));
      });
    });
  }, []);

  useEffect(() => {
    // console.log(user);
  }, [user]);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { updateUser })(
  App
);
