import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Grid } from "@mui/material";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert } from "@mui/material";

const BrandExperienceSection: FC = () => {
  const [currentBrands, setCurrentBrands] = useState([{ name: "", achievements: "" }]);
  const [pastBrands, setPastBrands] = useState([{ name: "", achievements: "" }]);
  const [sbState, setSBState] = useState({
    open: false,
  });
  const { open } = sbState;

  const handleBrandChange = (index: number, field: string, value: string, type: string) => {
    const updatedBrands = type === "current" ? [...currentBrands] : [...pastBrands];
    updatedBrands[index] = { ...updatedBrands[index], [field]: value };
    type === "current" ? setCurrentBrands(updatedBrands) : setPastBrands(updatedBrands);
  };

  const handleAddBrand = (type: string) => {
    const newBrand = { name: "", achievements: "" };
    type === "current" ? setCurrentBrands([...currentBrands, newBrand]) : setPastBrands([...pastBrands, newBrand]);
  };

  const handleUpdateExperience = () => {
    openSuccessSnackbar({ vertical: 'bottom', horizontal: 'center' });
  };

  const openSuccessSnackbar = (position: SnackbarOrigin) => {
    setSBState({ ...position, open: true });
  };

  const handleCloseSB = () => {
    setSBState({ ...sbState, open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>
      <div className="space-y-8">
        <div>
          <h2 className="text-2xl font-semibold">Brands You Currently Work With</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            List the brands you are currently collaborating with, including achievements.
          </span>
        </div>

        {currentBrands.map((brand, index) => (
          <div key={`current-brand-${index}`}>
            <Input
              placeholder="Brand Name"
              value={brand.name}
              onChange={(e) => handleBrandChange(index, "name", e.target.value, "current")}
              style={{ marginBottom: "16px" }}
            />
            <Textarea
              placeholder="Realizations & Achievements"
              rows={4}
              value={brand.achievements}
              onChange={(e) => handleBrandChange(index, "achievements", e.target.value, "current")}
            />
          </div>
        ))}
        <ButtonPrimary onClick={() => handleAddBrand("current")}>Add Another Current Brand</ButtonPrimary>

        <div>
          <h2 className="text-2xl font-semibold mt-8">Brands You Worked With in the Past</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            List the brands you have worked with in the past, including achievements.
          </span>
        </div>

        {pastBrands.map((brand, index) => (
          <div key={`past-brand-${index}`}>
            <Input
              placeholder="Brand Name"
              value={brand.name}
              onChange={(e) => handleBrandChange(index, "name", e.target.value, "past")}
              style={{ marginBottom: "16px" }}
            />
            <Textarea
              placeholder="Realizations & Achievements"
              rows={4}
              value={brand.achievements}
              onChange={(e) => handleBrandChange(index, "achievements", e.target.value, "past")}
            />
          </div>
        ))}
        <ButtonPrimary onClick={() => handleAddBrand("past")}>Add Another Past Brand</ButtonPrimary>

        <Grid container direction={'row'} className={'fullWidth'}>
          <div className={'flex-1'}></div>
          <ButtonPrimary onClick={handleUpdateExperience}>Save Changes</ButtonPrimary>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSB}
        message="Experience Updated"
      >
        <Alert
          onClose={handleCloseSB}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Brand Experience Updated!
        </Alert>
      </Snackbar>
    </>
  );
};

export default BrandExperienceSection;
