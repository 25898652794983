import React, { useState } from "react";
import FormItem from "./FormItem";
import Select from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const MarketSection = () => {
  const initialValues = {
    regionPresence: "",
    productCategories: "",
    targetGroups: "",
  };

  const [inputValues, setInputValues] = useState(initialValues);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { open } = snackbarState;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    console.log("Saved Market Section:", inputValues);
    openSuccessSnackbar();
  };

  const openSuccessSnackbar = () => {
    setSnackbarState({ open: true });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>
      <div className="space-y-8">
        <FormItem label="Activity Regions / Regions of Operation">
          <Select
            name="regionPresence"
            value={inputValues.regionPresence}
            onChange={handleInputChange}
          >
            <option value="">Select a Region</option>
            <option value="Germany (North)">Germany (North)</option>
            <option value="Germany (South)">Germany (South)</option>
            <option value="Germany (East)">Germany (East)</option>
            <option value="Germany (West)">Germany (West)</option>
            <option value="Austria">Austria</option>
            <option value="Switzerland">Switzerland</option>
          </Select>
        </FormItem>
        <FormItem label="Product Categories">
          <Select
            name="productCategories"
            value={inputValues.productCategories}
            onChange={handleInputChange}
          >
            <option value="">Select a Category</option>
            <option value="Electronics">Electronics</option>
            <option value="Fashion">Fashion</option>
            <option value="Home & Garden">Home & Garden</option>
            <option value="Health & Beauty">Health & Beauty</option>
            <option value="Sports & Outdoors">Sports & Outdoors</option>
          </Select>
        </FormItem>
        
        {/* Requires development of new features */}
        <FormItem label="Target Groups">
          <Select
            name="targetGroups"
            value={inputValues.targetGroups}
            onChange={handleInputChange}
          >
            <option value="">Select a Target Group</option>
            <option value="Luxury">Luxury</option>
            <option value="Mid-Market">Mid-Market</option>
            <option value="Discounter">Discounter</option>
          </Select>
        </FormItem>

        <Grid container direction={"row"} className={"fullWidth"}>
          <div className={"flex-1"}></div>
          <ButtonPrimary onClick={handleSaveChanges}>Save Changes</ButtonPrimary>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message="Market Section Saved"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Market Section Saved Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default MarketSection;
