import React, { useState } from "react";
import FormItem from "./FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import RadioGroup from "shared/RadioGroup/RadioGroup";
import Textarea from "shared/Textarea/Textarea";
import { Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const HowYouWorkSection = () => {
  const initialValues = {
    b2bNetworkSize: "",
    salesStrategy: "",
    tradeFairsAttended: "",
  };

  const [inputValues, setInputValues] = useState(initialValues);
  const [showTradeFairs, setShowTradeFairs] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { open } = snackbarState;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSalesStrategyChange = (value) => {
    setInputValues((prevState) => ({
      ...prevState,
      salesStrategy: value,
    }));
    setShowTradeFairs(value === "Trade Fairs or Events");
  };

  const handleSaveChanges = () => {
    console.log("Saved How You Work Section:", inputValues);
    openSuccessSnackbar();
  };

  const openSuccessSnackbar = () => {
    setSnackbarState({ open: true });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>
      <div className="space-y-8">
        <FormItem label="Size of B2B Network (Number of Retailers)">
          <input
            type="number"
            name="b2bNetworkSize"
            value={inputValues.b2bNetworkSize}
            onChange={handleInputChange}
            className="input"
          />
        </FormItem>
        <FormItem label="Preferred Sales Strategy">
          <RadioGroup
            name="salesStrategy"
            options={["Physical contact", "Digital", "Hybrid", "Trade Fairs or Events"]}
            value={inputValues.salesStrategy}
            onChange={handleSalesStrategyChange}
          />
        </FormItem>
        {showTradeFairs && (
          <FormItem label="Specify what trade fairs or events you attended">
            <Textarea
              name="tradeFairsAttended"
              value={inputValues.tradeFairsAttended}
              onChange={handleInputChange}
              placeholder="List the trade fairs or events you have attended"
            />
          </FormItem>
        )}

        <Grid container direction={"row"} className={"fullWidth"}>
          <div className={"flex-1"}></div>
          <ButtonPrimary onClick={handleSaveChanges}>Save Changes</ButtonPrimary>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message="How You Work Section Saved"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          How You Work Section Saved Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default HowYouWorkSection;
