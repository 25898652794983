import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import BrandCard from "components/BrandCard/StayCard";
import SectionDetails from './SectionDetails';
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import SectionAttachImages from './SectionAtttachImages';
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { logout, updateUser } from "../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import api from '../../api';
import AvailabilitySection from "./AvailabilitySection";
import ContactInformationSection from "./ContactInformationSection";
import AboutSection from "./AboutSection";
import MarketSection from "./MarketSection";
import HowYouWorkSection from "./HowYouWorkSection";
import BrandExperienceSection from "./BrandExperienceSection";
import SalesDetailSection from "./SalesDetailSection";
import MediaSection from "./MediaSection";

const AgencyTabs = [
  "Details", 
  "Profile Images", 
  "Media Uploads",
  "Availability",
  "Contact",
  "About",
  "Markets",
  "How You Work",
  "Brand Experience",
  "Sales Details"];
const BrandTabs = ['Brands', "Details", "Profile Images"];

const PageAccount = ({ className, user }) => {
  const navigate = useNavigate();

  const onBrandCreate = () => {
    navigate('/create-brand');
  };

  const [activeTab, setActiveTab] = useState('details');

  const renderSidebar = () => {
    const tabs = user?.company_type === 'agencies' ? AgencyTabs : BrandTabs;

    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={user?.company?.avatar ? api.getUploadUrl() + '/' +user?.company?.avatar?.url : null}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-xl font-semibold">
            {user.company?.name.length > 20 ? 
              user.company?.name.substring(0, 20) + '...' :
              user.company?.name
            }
          </h2>
          {/* <StartRating className="!text-base" /> */}
        </div>

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {`${user.company?.street_address} ${user.company?.city}, ${user.company?.country}`}
            </span>
          </div>

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              12 People Viewed your Profile
            </span>
          </div>
        </div>

        <div className="mt-4 flex flex-col space-y-2">
          {tabs.map((item) => (
            <a
              key={item}
              href={`#${item.toLowerCase().replace(' ', '-')}`}
              className={`text-neutral-500 dark:text-neutral-400 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-md px-3 py-2 capitalize ${
                activeTab === item.toLowerCase().replace(' ', '-') ? 'bg-neutral-200 dark:bg-neutral-700' : ''
              }`}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(item.toLowerCase().replace(/ /g, '-'));
                document
                  .getElementById(item.toLowerCase().replace(' ', '-'))
                  ?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {item}
            </a>
          ))}
        </div>


      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        {activeTab === 'brands' && user?.company_type === 'brandowners' && (
          <div id="brands">
            <button
              style={{ marginLeft: 4, borderRadius: '5px', margin: '20px 12px' }}
              onClick={onBrandCreate}
            >
              Create Brand Profile
            </button>
            <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
              {user?.company?.brands?.map((brand) => {
                let brandData = {
                  galleryImgs: brand.images.map((b) => `${api.getUploadUrl()}/${b.url}`),
                  title: brand.name,
                  href: `/brand/${brand.id}`,
                };
                return <BrandCard key={brand.id} data={brandData} />;
              })}
            </div>
          </div>
        )}
  
        {activeTab === 'details' && (
          <div id="details">
            <SectionDetails />
          </div>
        )}
  
        {activeTab === 'profile-images' && (
          <div id="profile-images" style={{ marginTop: 35 }}>
            {/* <h3 className="text-lg font-semibold">{user.company?.name}</h3> */}
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Profile Avatar
            </span>
            <SectionAttachImages imgSet={user?.company?.avatar ? [user?.company?.avatar] : []} user={user} attachmentKey={'avatar'} multiple={false} />
  
            {/* <h3 className="text-lg font-semibold">{user.company?.name}</h3> */}
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Portfolio Images
            </span>
            <SectionAttachImages imgSet={user?.company?.images} user={user} attachmentKey={'brand_image'} multiple={true} />
          </div>
        )}

        {activeTab === 'media-uploads' && (
          <div id="media-uploads">
          <MediaSection user={undefined} attachmentKey={undefined}/>

          </div>
        )}

        {activeTab === 'availability' && (
          <div id="availability">
            <AvailabilitySection />
          </div>
        )}

        {activeTab === 'contact' && (
          <div id="contact">
            <ContactInformationSection />
          </div>
        )}

        {activeTab === 'about' && (
          <div id="about">
            <AboutSection />
          </div>
        )}

        {activeTab === 'markets' && (
          <div id="markets">
            <MarketSection />
          </div>
        )}

        {activeTab === 'how-you-work' && (
          <div id="how-you-work">
            <HowYouWorkSection />
          </div>
        )}
        
        {activeTab === 'brand-experience' && (
          <div id="brand-experience">
            <BrandExperienceSection />
          </div>
        )}
        
        {activeTab === 'sales-details' && (
          <div id="sales-details">
            <SalesDetailSection />
          </div>
        )}

      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {/* {renderSection2()} */}
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { updateUser, logout })(
  PageAccount
);
