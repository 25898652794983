import { Grid } from '@mui/material';
import React, { useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const AvailabilitySection = () => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { open } = snackbarState;

  const availabilityOptions = [
    {
      id: 'open',
      label: 'Open for new brand partnerships',
    },
    {
      id: 'selective',
      label: 'Quite busy but open for select requests',
    },
    {
      id: 'closed',
      label: 'Fully booked, no requests at this time',
    },
  ];

  const handleSaveChanges = () => {
    console.log('Saved Availability Information:', { selectedStatus, selectedFile });
    openSuccessSnackbar();
  };

  const openSuccessSnackbar = () => {
    setSnackbarState({ open: true });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>

      <div className="space-y-8">
        <h2 className="text-2xl font-semibold">Availability Status</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Indicate your current capacity for new partnerships
        </span>

        <div className="mt-5">
          <div className="mt-1">
            <div className="space-y-4">
              {availabilityOptions.map((option) => (
                <div
                  key={option.id}
                  className={`relative flex items-start p-4 border-2 rounded-xl hover:border-primary-500 cursor-pointer transition-colors ${
                    selectedStatus === option.id
                      ? 'border-primary-500'
                      : 'border-neutral-200 dark:border-neutral-700'
                  }`}
                  onClick={() => setSelectedStatus(option.id)}
                >
                  <div className="min-w-0 flex-1 text-sm">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id={option.id}
                        checked={selectedStatus === option.id}
                        onChange={() => setSelectedStatus(option.id)}
                        className="h-4 w-4 text-primary-600 border-neutral-300 focus:ring-primary-500"
                      />
                      <label
                        htmlFor={option.id}
                        className="ml-3 font-medium text-neutral-900 dark:text-neutral-200"
                      >
                        {option.label}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6">
            <Grid container direction="row" className="fullWidth">
              <div className="flex-1"></div>
              <ButtonPrimary onClick={handleSaveChanges}>Save Changes</ButtonPrimary>
            </Grid>
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message="Availability Information Saved"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Availability Information Saved Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default AvailabilitySection;
