import React, { useState } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import FormItem from "./FormItem";
import Select from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const AboutSection = () => {
  const initialValues = {
    agencyName: "",
    languages: "",
    availability: "",
    showroomAddress: "",
    aboutAgency: "",
    industryExpertise: "",
    yearsOfExperience: "",
    numberOfAgents: "",
    brandPartnershipGoals: "",
    salesGoals: "",
    strengths: "",
  };

  const [inputValues, setInputValues] = useState(initialValues);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { open } = snackbarState;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    console.log("Saved About Section:", inputValues);
    openSuccessSnackbar();
  };

  const openSuccessSnackbar = () => {
    setSnackbarState({ open: true });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>
      <div className="space-y-8">
        <FormItem label="Agency Name">
          <Input
            placeholder="Agency Name"
            name="agencyName"
            value={inputValues.agencyName}
            onChange={handleInputChange}
          />
        </FormItem>
        {/* Requires development of new features */}
        <FormItem label="Languages">
          <Select
            name="languages"
            value={inputValues.languages}
            onChange={handleInputChange}
          >
            <option value="">Select a Language</option>
            <option value="English">English</option>
            <option value="Spanish">Spanish</option>
            <option value="French">French</option>
            <option value="German">German</option>
          </Select>
        </FormItem>
        <FormItem label="Availability">
          <Input
            type="date"
            name="availability"
            value={inputValues.availability}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="Showroom Address(es) (Optional)">
          <Textarea
            placeholder="Showroom Address(es)"
            name="showroomAddress"
            rows={3}
            value={inputValues.showroomAddress}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="About the Agency">
          <Textarea
            placeholder="About the Agency"
            name="aboutAgency"
            rows={4}
            value={inputValues.aboutAgency}
            onChange={handleInputChange}
          />
        </FormItem>
        {/* Requires development of new features */}
        <FormItem label="Industry Expertise">
          <Input
            placeholder="Tags"
            name="industryExpertise"
            value={inputValues.industryExpertise}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="Years of Experience in the Industry">
          <Input
            type="number"
            name="yearsOfExperience"
            value={inputValues.yearsOfExperience}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="Number of Agents Working at Your Agency">
          <Input
            type="number"
            name="numberOfAgents"
            value={inputValues.numberOfAgents}
            onChange={handleInputChange}
          />
        </FormItem>
        <div>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Vision and Goals
          </span>
        </div>
        <FormItem label="What Are You Looking for in a Brand Partnership?">
          <Textarea
            placeholder="Your goals for brand partnerships"
            name="brandPartnershipGoals"
            rows={4}
            value={inputValues.brandPartnershipGoals}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="Your Long-Term Goals in Sales">
          <Textarea
            placeholder="Your long-term sales goals"
            name="salesGoals"
            rows={4}
            value={inputValues.salesGoals}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem label="Your Strengths">
          <Textarea
            placeholder="Your strengths (e.g., customer acquisition, relationship management)"
            name="strengths"
            rows={4}
            value={inputValues.strengths}
            onChange={handleInputChange}
          />
        </FormItem>

        <Grid container direction={"row"} className={"fullWidth"}>
          <div className={"flex-1"}></div>
          <ButtonPrimary onClick={handleSaveChanges}>Save Changes</ButtonPrimary>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message="About Section Saved"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          About Section Saved Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default AboutSection;
