import { Popover, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

const Regions = ({regions, setRegions}) => {
    // const handleSelectRegion = (regionId : number) => {
    //     console.log(regionId);
    //     
    // }

    const handleChange = (event) => {
        setRegions((prevState) => {
            return prevState.map((state) => {
                if (state.id === parseInt(event.target.value)) {
                    state.selected = event.target.checked
                }
                return state
            });
        });
    }

    return (
        <>
            <div className="w-full max-w-sm">
                <Popover className="relative mb-4">
                    {({ open }) => (
                        <>
                            <Popover.Button
                                className={`
                                    ${open ? 'text-white' : 'text-white/90'}
                                    group inline-flex items-center rounded-md bg-orange-700 px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                            >
                                <span>Regions</span>
                                <ChevronUpDownIcon
                                    className={`ml-2 h-5 w-5 transition duration-150 ease-in-out`}
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute right-0 z-10 mt-3 w-64 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                        <div className="relative grid gap-8 bg-white p-4 lg:grid-cols-2">
                                            {regions.length ? (
                                                <ul className='w-56'>
                                                    {regions.map((region) => {
                                                        return (
                                                            <li 
                                                                className="regions p-1"
                                                                key={region.id}
                                                            >
                                                                <label htmlFor={region.id}>
                                                                    {region.name}
                                                                </label>
                                                                <input 
                                                                    id={region.id}
                                                                    type='checkbox' 
                                                                    checked={region.selected ? true : false} 
                                                                    value={region.id} 
                                                                    onChange={(e) => {handleChange(e)}}
                                                                />
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            ) : (
                                                <p>No Data</p>
                                            )}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </div>
            <div className='flex max-w-[100px] gap-5'>
                {regions.length && 
                    regions.map((region) => {
                        return region.selected && (
                            // <div className='min-w-[150px] max-w-[200px] text-center'>{region.name}</div>
                            <span 
                                className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                                key={region.id}
                            >
                                {region.name}
                            </span>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Regions
