import React, { useState } from "react";
import FormItem from "./FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Grid, LinearProgress, Stack } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import api from "../../api";

const MediaSection = ({ user, attachmentKey, multiple = true, imgSet = [] }) => {
  const initialImages = imgSet.map((i) => ({
    isLoading: false,
    name: i.url,
    url: `${api.getUploadUrl()}/${i.url}`,
  }));

  const [images, setImages] = useState(initialImages);
  const [imagesUploading, setImagesUploading] = useState([]);
  const [video, setVideo] = useState(null);
  const [videoUploading, setVideoUploading] = useState(false);
  const [sbState, setSBState] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  

  const isFileExists = (f) => images.some((img) => img.name === f.name);

  const handleOnChange = (e) => {
    const files = Array.from(e.target.files);
    const imagesTmp = [...images];
  
    files.forEach((file) => {
      const f = file as File; 
      if (!f.name || isFileExists(f)) return;
      imagesTmp.push({ isLoading: true, name: f.name, url: "" });
      setImagesUploading(imagesTmp);
  
      const formData = new FormData();
      formData.append("file", f); 
      formData.append("key", attachmentKey);
      formData.append("entity", user?.company_type === "agencies" ? "agencies" : "brandowners");
      formData.append("entity_id", user?.company?.id);
  
      api.uploadAttachment(formData, (res) => {
        cleanUpLoadingImages(f.name);
        handleUploadSuccess(res.data);
      });
    });
  };
  

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setVideoUploading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("key", attachmentKey);
    formData.append("entity", user?.company_type === "agencies" ? "agencies" : "brandowners");
    formData.append("entity_id", user?.company?.id);

    api.uploadAttachment(formData, (res) => {
      setVideo({
        name: file.name,
        url: `${api.getUploadUrl()}/${res.data.url}`,
      });
      setVideoUploading(false);
      openSnackbar("Video uploaded successfully!", "success");
    });
  };

  const cleanUpLoadingImages = (name) => {
    setImagesUploading((prev) => prev.filter((img) => img.name !== name));
  };

  const handleUploadSuccess = (file) => {
    file.url = `${api.getUploadUrl()}/${file.url}`;
    setImages((prev) => [...prev, file]);
  };

  const openSnackbar = (message, severity) => {
    setSBState({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSBState({ ...sbState, open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>
      <div className="space-y-8">
        <FormItem label="Pictures of Your Showroom and Team (Max: 8 images)">
          <Stack direction={"row"} spacing={1} useFlexGap sx={{ flexWrap: "wrap" }}>
            {images.map((r, i) => (
              <ImageItem key={i} {...r} />
            ))}
            {imagesUploading.map((r, i) =>
              r.isLoading ? <ImageItem key={`uploading-${i}`} {...r} /> : null
            )}
          </Stack>
          <div className="mt-5">
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                  <label
                    htmlFor={`file-upload-${attachmentKey}`}
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>Upload files</span>
                    <input
                      id={`file-upload-${attachmentKey}`}
                      name="files"
                      type="file"
                      multiple={multiple}
                      className="sr-only"
                      onChange={handleOnChange}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </div>
        </FormItem>

        <FormItem label="Upload a Video of Your Showroom (Max: 2 minutes)">
          <div className="mt-5">
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                  <label
                    htmlFor={`video-upload-${attachmentKey}`}
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>Upload video</span>
                    <input
                      id={`video-upload-${attachmentKey}`}
                      name="video"
                      type="file"
                      accept="video/*"
                      className="sr-only"
                      onChange={handleVideoUpload}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                  MP4, MOV up to 2 minutes
                </p>
              </div>
            </div>
          </div>
          {videoUploading && <LinearProgress />}
          {video && (
            <div className="mt-3">
              <strong>Uploaded Video:</strong> {video.name}
              <video src={video.url} controls width="100%" />
            </div>
          )}
        </FormItem>

        <Grid container direction={"row"} className={"fullWidth"}>
          <div className={"flex-1"}></div>
          <ButtonPrimary onClick={() => openSnackbar("Media uploaded successfully!", "success")}>Upload Media</ButtonPrimary>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={sbState.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={sbState.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {sbState.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MediaSection;

const ImageItem = ({isLoading, name, url}) => {
    if(url==='')
      return (
        <div className="image-item-box space-y-1 text-center">
          <div>{name}</div>
          {
            isLoading ? <LinearProgress sx={{ bottom: '0' }} /> : null
          }
        </div>
      );
    else
      return (
        <div className="image-item-box space-y-1 text-center" style={{ background: `url("${url}")`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
        </div>
    );
  }