import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import api from '../../api';
import { connect, useDispatch } from "react-redux";
import { logout, updateUser } from "../../redux/reducers/userReducer";

export interface PageLoginProps {
    className?: string;
    user?: Object;
}

const PageLogin: FC<PageLoginProps> = ( className, { user } ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');

    const initialValues = {
        "username": "",
        "password": "",
        "grant_type": "password",
        "client_id": process.env.REACT_APP_CLIENT_ID,
        "client_secret": process.env.REACT_APP_CLIENT_SECRET,
        "scope": ""
    }
    const [inputValues, setInputValues] = useState(initialValues);
    const handleInputChange = (e) => {
        setError('');
        setInputValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
        }));
    };

    const handleLoginSubmit = () => {
        setError('');
        api.auth(inputValues, (res) => {
            if (res && res.status === 200) {
                if ( res.data.access_token ) {
                    window.location.reload();
                } else {
                    setError('Invalid Request');
                }
            } else{
                if (res.status === 403) {
                    setError(res.data.error);
                } else if (res.status === 401) {
                    setError(res.data.error);
                } else {
                    setError(res.data.error);
                }
            }
            
        });
    };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Agent Tinder</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* Error Message */}
          {error && (
            <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
              {error}
            </div>
          )}
          
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name='username'
                onChange={handleInputChange}
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input 
                type="password" 
                name='password'
                onChange={handleInputChange}
                className="mt-1" />
            </label>
            <ButtonPrimary onClick={handleLoginSubmit}>Continue</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/register">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { updateUser, logout })(
  PageLogin
);
