import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC, useEffect } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export interface SearchResultListPageProps {
  className?: string;
}

const SearchResultListPage: FC<SearchResultListPageProps> = ({ className = "" }) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const keywords = queryParams.get('q') ?? '';
  const regions = queryParams.get('r')
  const categories = queryParams.get('categories');

  useEffect(() => {
    // trigger search
    console.log('regions',regions);
  }, []);
  
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Agent Tinder | Search</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">

        {/* SECTION */}
        <SectionGridFilterCard keywords={keywords} regions={regions} className="pt-10 pb-24 lg:pb-28" />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by product group"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchResultListPage;
