export const calculateProfileCompletion = (user) => {
  const sections = [
    user?.company?.details,
    user?.company?.profileImages,
    user?.company?.media,
    user?.company?.availability,
    user?.company?.contactInfo,
    user?.company?.about,
    user?.company?.markets,
    user?.company?.howYouWork,
    user?.company?.brandExperience,
    user?.company?.salesDetails,
  ];

  let totalFields = 0;
  let filledFields = 0;

  sections.forEach((section) => {
    if (section && typeof section === "object") {
      Object.values(section).forEach((field) => {
        totalFields++;
        if (field) filledFields++;
      });
    }
  });

  return { totalFields, filledFields };
};
