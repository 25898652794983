import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";
import { Grid } from "@mui/material";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert } from "@mui/material";

const SalesDetailSection: FC = () => {
  const initialValues = {
    numberOfDealers: "",
    priceRange: "",
  };

  const [inputValues, setInputValues] = useState(initialValues);
  const [sbState, setSBState] = useState({
    open: false,
  });
  const { open } = sbState;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSaveChanges = () => {
    openSuccessSnackbar({ vertical: 'bottom', horizontal: 'center' });
  };

  const openSuccessSnackbar = (position: SnackbarOrigin) => {
    setSBState({ ...position, open: true });
  };

  const handleCloseSB = () => {
    setSBState({ ...sbState, open: false });
  };

  return (
    <>
      <div style={{ marginTop: 26 }} className="w-14"></div>
      <div className="space-y-8">
        <FormItem label="Number of Dealers You Are Selling To">
          <Input
            type="number"
            placeholder="Enter number of dealers"
            name="numberOfDealers"
            value={inputValues.numberOfDealers}
            onChange={handleInputChange}
          />
        </FormItem>

        <FormItem label="Price Range of Products You Sell (Per Category)">
          <Input
            placeholder="e.g., €50–€200, €500+"
            name="priceRange"
            value={inputValues.priceRange}
            onChange={handleInputChange}
          />
        </FormItem>

        <Grid container direction={"row"} className={"fullWidth"}>
          <div className={"flex-1"}></div>
          <ButtonPrimary onClick={handleSaveChanges}>Save Changes</ButtonPrimary>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSB}
        message="Sales Details Updated"
      >
        <Alert onClose={handleCloseSB} severity="success" variant="filled" sx={{ width: "100%" }}>
          Sales Details Saved Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default SalesDetailSection;
